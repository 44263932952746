// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Collection from "@components/partials/Collection"

const CollectionPage = () => (
  <Layout
    title="Original Collection || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-white"
    page="collection"
  >
    <Collection />
  </Layout>
)
export default CollectionPage
